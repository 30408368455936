<template>
  <div id="about-roomcast">
    <VueSlickCarousel ref="about_roomcast_carousel" v-bind="carousel_settings" @beforeChange="checkLastSlide">
      <div class="about-roomcast-slide slide-wrapper">
        <div class="img-wrapper">
          <img src="@/assets/images/room_settings/about_roomcast_1.png" alt="about-roomcast" width="227">
        </div>
        <h3>What is a Roomcast?</h3>
        <p>Roomcasts are special messages sent through Camfrog that notify your room members an upcoming gathering or event.</p>
      </div>
      <div class="about-roomcast-slide slide-wrapper">
        <div class="img-wrapper">
          <img src="@/assets/images/room_settings/about_roomcast_2.png" alt="about-roomcast" width="159">
        </div>
        <h3>How do I use it?</h3>
        <p>To send a message simply select a template, fill out details, and click ‘publish’.</p>
      </div>
      <div class="about-roomcast-slide slide-wrapper">
        <div class="img-wrapper">
          <img src="@/assets/images/room_settings/about_roomcast_3.png" alt="about-roomcast" width="134">
        </div>
        <h3>Enjoy the conversation</h3>
        <p>Now none of your room members will miss upcoming events!</p>
      </div>
    </VueSlickCarousel>
    <div style="margin-top: 32px;">
      <Button
        type="green"
        :value="buttonText"
        :height="40"
        :width="120"
        @click="toNext"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

export default {
  name: "AboutRoomcast",
  components: {
    VueSlickCarousel,
    Button
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      carousel_settings: {
        arrows: false,
        dots: true,
        dotsClass: 'slider-nav-dots',
        focusOnSelect: true,
        infinite: false,
        slidesToShow: 1,
      },
      is_final_screen: false,
    }
  },
  computed: {
    buttonText() {
      return this.is_final_screen ? 'OK' : 'Next';
    }
  },
  methods: {
    toNext() {
      if (this.is_final_screen) {
        this.$store.dispatch('closeModal');
      } else {
        this.$refs.about_roomcast_carousel.next();
      }
    },
    checkLastSlide(old_index, new_index) {
      this.is_final_screen = new_index === 2;
    }
  }
}
</script>

<style scoped>
#about-roomcast {
  padding: 30px 30px 40px;
  text-align: center;
  color: #565656;
  box-sizing: border-box;
  max-width: 460px;
}
.about-roomcast-slide {
  max-width: 270px;
}
.about-roomcast-slide h3 {
  font-size: 16px;
  line-height: 22px;
  margin: 20px 0 10px;
}
.about-roomcast-slide p {
  font-size: 14px;
  line-height: 19px;
}
#about-roomcast .img-wrapper img {
  display: inline-block;
}
</style>
